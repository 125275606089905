import React, { Component, Suspense } from "react";

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));
class RefundPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
    };
  }

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={'bg-nav'}
            imglight={this.state.imglight}
          />
          <section className="privacyPolicy-box">
            <h1>Refund Policy</h1>
            <p>Created: June 17, 2023</p>
            <p>
              In order to protect the interests of consumers, FuTao now promises "7 days no reason to return and exchange". If you need to return or exchange the goods, please contact us by email. After communicating with customer service, we will deal with it according to your requirements. If you need a refund, according to the following processing plan, I hope to get user support and wait patiently, and pay attention to check.
            </p>
            <p>
              Refund method: return to the original route, return within 3 to 15 working days. The speed of refund depends on different countries and different credit card types, and the speed of refund to the account is different.
            </p>
          </section>
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default RefundPolicy;

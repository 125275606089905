import React, { Component, Suspense } from "react";

const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));
const Section = React.lazy(() => import("./Section"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));

class Layout5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imglight: false,
      navClass: "navbar-light bg-white",
    };
  }

  // Loader
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={[]}
            navClass={'bg-nav'}
            imglight={true}
          />

          {/* Importing Section */}
          <Section />
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Layout5;

import React, { Component } from "react";
import routes from "../src/routes";
import {
  withRouter,
  Route,
  Switch,
  HashRouter as Router,
} from "react-router-dom";
import ScrollToTop from "../src/component/ScrollToTop";
import "./App.scss";

//import style
import "./assets/css/pe-icon-7.css";
import "./assets/css/materialdesignicons.min.css";

import "./assets/scss/themes.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop>
            <Switch>
              {routes.map((route, idx) => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={idx}
                />
              ))}
            </Switch>
          </ScrollToTop>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);

import React, { Component, Suspense } from "react";

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));
import tiktok from "../../assets/images/my-pic/mycard@245x245.png";
import visaImg from "../../assets/images/my-pic/visaImg@130x40.png";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
class mycardCharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
      currentVal: '9.00',
      priceList:[
        {label: 250, val: '9.00'},
        {label: 750, val:'26.99'},
        {label: 1500, val:'48.99'},
        {label: 2800, val: '86.99'},
      ],
      selectIndex:0
    };
  }

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };
  selectTypeFunc = (data, index) => {
    const { val } = data;
    this.setState({
      selectIndex: index,
      currentVal: val
    });
  }
  render() {
    const {priceList, selectIndex, currentVal} = this.state;
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={'bg-nav'}
            imglight={this.state.imglight}
          />
          <Container className="charge-page">
            <h2 className="fw-bold" style={{marginBottom:'40px'}}>MyCard</h2>
            <Row className="align-items-center mb-5">
              <Col md={5} className="order-1 order-md-1">
                <div className="ms-5 features-img">
                  <img src={tiktok} alt="" className="img-fluid d-block mx-auto rounded shadow"/>
                </div>
              </Col>
              <Col md={7} className="order-2 order-md-2 ms-md-auto  mt-md-0 mt-5">
                <div className="charge-title">MyCard</div>
                <span className="charge-tips">Manual processing, please contact customer service by email</span>
                <section className="charge-item text-item">
                  <span className="label">Price：</span>
                  <span className="value">USD ${currentVal}</span>
                </section>
                <section className="charge-item text-item" style={{marginBottom:'12px'}}>
                  <span className="label">Type：</span>
                  <span className="bg-value">Point Card</span>
                </section>
                <section className="charge-item">
                  <span className="label">value：</span>
                  <ul className="price-list">
                    {priceList.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={selectIndex === index ? 'on' : ''}
                          onClick={() => this.selectTypeFunc(item, index)}
                        >
                          {item.label}
                          <span />
                        </li>
                      );
                    })}
                  </ul>
                </section>
                <Link to="/payment">
                  <button
                    type="submit"
                    name="send"
                    className="btn btn-primary buy-btn"
                  >
                    Buy
                  </button>
                </Link>
				<img src={visaImg} alt="" className="img-fluid d-block pay-way-img"/>
              </Col>
            </Row>
            <div className="cut-line"/>
            <Row className="charge-description align-items-center mb-5">
              <Col>
                <h3 className="fw-bold" style={{marginBottom:'40px'}}>Product desciption</h3>
                <section>
                  <p>Commodity type: Mycard point card</p>
                  <p>Product brand: Mycard</p>
                  <p>Scope of application:</p>
                  <p>MyCard can be recharged to use the following games:</p>
                </section>
                <section>
                  <p>China Net Dragon: The Legend of Huang Yi Heroes Free, The Legend of the Six Saints, The Legend of Jin Yong Heroes, The Legend of Jin Yong Heroes Free, Jin Yong-Ultimate Martial Arts, The Legend of Jin Yong Heroes 2, The Legend of Jin Yong Heroes 2 Free, Three Kingdoms Free , Lunia Wars, Romance of the Three Kingdoms, Romance of the Three Kingdoms for free, Oriental Legend, Legend of Wulin Heroes for free, Kabala Island for free, Love Box, Love Box for free, Three Kingdoms on the Internet, Heroes of Jiuzhou for free, Drifting Fantasyland for free, Xinshu Mountain Swordsman Free, Chinese Mahjong Hall, Swallowing World, Swallowing World Free, Swallowing World 2 Free, XBA Basketball Manager, Advent of the Gods, Adventurer Story, Jianghu Dominance, Regal Street, Lord, Earth Empire, God of War World, Jingke Assassin King Qin, Star Ship Qihang, Nordic mythology, swords and rivers and lakes.</p>
                </section>
                <section>
                  <p>Game Shinkansen: Ragnarok, Legend 3, Swordsman Love, New Peerless Twins, Three Kingdoms Heroes, Nobunaga's Ambition, Flame Dragon Knights, Swordsman Love II, Wicked Ghost, Angel Love, Perfect World, Twelve Heavens, Tennis Racquet, Ultimate, Wulin Gaiden, Web Three Kingdoms, Tianlong Babu, Zhu Xian, Fenghuo Journey, Basketball Fire, Twelve Heavens II.</p>
                </section>
                <section>
                  <p>OMG: Feifei, Adventure of Light, The Ninth Seal, Monarch, God May Cry, Kung Fu World, LUNA.</p>
                </section>
                <section>
                  <p>Yujun Aoting: Peerless Mahjong (Mahjong rubbing), Three Kingdoms Heroes and Tycoon, Destiny Warrior, Demon Realm, Angel Love 2.</p>
                </section>
                <section>
                  <p>GGC: Audition, Diablo, Superstar, PokiPoki.</p>
                </section>
                <section>
                  <p>Tianyu: Isio's Dream, Golf, Infernal Affairs, Racecourse.</p>
                </section>
                <section>
                  <p>Others: Xingmeng, Golden Taiwan Star Casino, Uniform Mahjong, Conquer, Big Sweet Potato, Groundhog Game Network, Chessmate and other Taiwan server games.</p>
                </section>
                <section>
                  <p>For more details, please visit the website: http://www.mycard520.com.tw for inquiries</p>
                </section>
                <section>
                  <p>Applicable denominations and exchange ratios for each game are based on official website announcements. To recharge MyCard membership, verification of email address and mobile phone number is required</p>
                </section>
                <section>
                  <p>Product description:</p>
                  <p>1. Please fill in the game account, recharge partition, and recharge amount carefully, and click Confirm Purchase to complete the recharge. Do not tell others the card number and password before recharging</p>
                  <p>2. Please confirm that when you recharge, you must pay attention to entering the account number correctly. Once the recharge is successful, the system will not provide recharge correction services.</p>
                  <p>3. This product is an electronic card, and generally there will be no errors. If it prompts that the card number is wrong, please delete the space after the card number.</p>   
                </section>
              </Col>
            </Row>
          </Container>
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default mycardCharge;

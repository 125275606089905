import React, { Component, Suspense } from "react";

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));
import tiktok from "../../assets/images/my-pic/karmakoin@245x245.png";
import visaImg from "../../assets/images/my-pic/visaImg@130x40.png";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
class karmaKoinCharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
      currentVal: '7.00',
      priceList:[
        {label: '7 USD', val: '7.00'},
        {label: '20 USD', val:'20.00'},
        {label: '40 USD', val:'40.00'},
        {label: '80 USD', val: '80.00'},
      ],
      selectIndex:0
    };
  }

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };
  selectTypeFunc = (data, index) => {
    const { val } = data;
    this.setState({
      selectIndex: index,
      currentVal: val
    });
  }
  render() {
    const {priceList, selectIndex, currentVal} = this.state;
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={'bg-nav'}
            imglight={this.state.imglight}
          />
          <Container className="charge-page">
            <h2 className="fw-bold" style={{marginBottom:'40px'}}>Karma Koin</h2>
            <Row className="align-items-center mb-5">
              <Col md={5} className="order-1 order-md-1">
                <div className="ms-5 features-img">
                  <img src={tiktok} alt="" className="img-fluid d-block mx-auto rounded shadow"/>
                </div>
              </Col>
              <Col md={7} className="order-2 order-md-2 ms-md-auto  mt-md-0 mt-5">
                <div className="charge-title">Karma Koin</div>
                <span className="charge-tips">Manual processing, please contact customer service by email</span>
                <section className="charge-item text-item">
                  <span className="label">Price：</span>
                  <span className="value">USD ${currentVal}</span>
                </section>
                <section className="charge-item text-item" style={{marginBottom:'12px'}}>
                  <span className="label">Type：</span>
                  <span className="bg-value">gold</span>
                </section>
                <section className="charge-item">
                  <span className="label">value：</span>
                  <ul className="price-list">
                    {priceList.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={selectIndex === index ? 'on' : ''}
                          onClick={() => this.selectTypeFunc(item, index)}
                        >
                          {item.label}
                          <span />
                        </li>
                      );
                    })}
                  </ul>
                </section>
                <Link to="/payment">
                  <button
                    type="submit"
                    name="send"
                    className="btn btn-primary buy-btn"
                  >
                    Buy
                  </button>
                </Link>
				<img src={visaImg} alt="" className="img-fluid d-block pay-way-img"/>
              </Col>
            </Row>
            <div className="cut-line"/>
            <Row className="charge-description align-items-center mb-5">
              <Col>
                <h3 className="fw-bold" style={{marginBottom:'40px'}}>Product desciption</h3>
                <section>
                  <p>Commodity type: point card</p>
                </section>
                <section>
                  <p>Commodity brand: Karma Koin</p>
                </section>
                <section>
                  <p>Scope of application: karma koin point card is the official recharge card of Nexon Game Cards MapleStory in the United States. After recharge, it can be used for all games of NEXON www.nexon.net.</p>
                </section>
                <section>
                  <p>Product description:</p>
                  <p>1. This product is an electronic card, and generally there will be no errors. If it prompts that the card number is wrong, please delete the space after the card number.</p>
                  <p>2. Do not tell others the card number and password before recharging. Once the recharging is successful, the system will not provide recharge correction services.</p>
                  <p>Support game query: http://karmakoin.com/where_to_use, this card can be recharged with 150,000 coupons per month as the upper limit.</p>
                </section>
              </Col>
            </Row>
          </Container>
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default karmaKoinCharge;

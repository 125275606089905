export default [
  {
    title: "台湾元气卡",
    img: require("../assets/images/product/1.jpg"),
    type: "Point Card",
    priceList: [
      { label: "200", val: "6.50" },
      { label: "500", val: "15.19" },
      { label: "1000", val: "32.49" },
      { label: "80 USD", val: "86.99" },
    ],
    desc: `
    Product type : game
    <div class="space"></div>
Product brand:  vitality card
<div class="space"></div>
Scope of application:  Game
    `,
  },
  {
    title: "GASH",
    type: "Point Card",
    priceList: [
      { label: "100", val: "3.89" },
      { label: "300", val: "10.29" },
      { label: "500", val: "15.59" },
      { label: "1000", val: "33.13" },
    ],
    img: require("../assets/images/product/2.png"),
    desc: `
    Product type: recharge card
    <div class="space"></div>
    Product brand: GASH
    <div class="space"></div>
    Scope of application: Hong Kong Orange GASH Hong Kong Service Universal Point Card
    <div class="space"></div>
    Product Description:
    <div class="space"></div>
    1. This product is an electronic card and generally does not have any errors. If there is an error in the card number, please remove the space after the card number.
    <div class="space"></div>
    2. Do not disclose the card number and password to others before recharging. Once the recharge is successful, the system will not provide recharge correction services.
    <div class="space"></div>
    GASH is a synonym for 'GAME CASH'; The online cash flow payment currency and platform mechanism provided by Game Orange Gamania.`,
  },
  {
    title: "GASH",
    type: "Point Card",
    priceList: [
      { label: "100", val: "4.15" },
      { label: "300", val: "11.28" },
      { label: "500", val: "17.58" },
      { label: "1000", val: "36.13" },
    ],
    img: require("../assets/images/product/3.png"),
    desc: `
    Product type: recharge card
    <div class="space"></div>
    Product brand: GASH
    <div class="space"></div>
    Scope of application: Hong Kong Orange GASH Hong Kong Service Universal Point Card
    <div class="space"></div>
    Product Description:
    <div class="space"></div>
    1. This product is an electronic card and generally does not have any errors. If there is an error in the card number, please remove the space after the card number.
    <div class="space"></div>
    2. Do not disclose the card number and password to others before recharging. Once the recharge is successful, the system will not provide recharge correction services.
    <div class="space"></div>
    GASH is a synonym for 'GAME CASH'; The online cash flow payment currency and platform mechanism provided by Game Orange Gamania.`,
  },
  // {
  //   title: "Xbox",
  //   img: require("../assets/images/product/4.png"),
  //   desc: `
  //   Product Type Game Point Card
  //   <div class="space"></div>
  //   Product brand Xbox
  //   <div class="space"></div>
  //   Applicable scope
  //   <div class="space"></div>
  //   Hong Kong Xbox360 XboxOne Live HKD Gift Card`,
  // },
  {
    title: "Gocash",
    img: require("../assets/images/product/5.jpg"),
    desc: `
    Product type: recharge card
    <div class="space"></div>
    Product brand: Meifu
    <div class="space"></div>
    Scope of application: Gocash NAVY/FIELD/Lexon Adventure Island International Server/Wakefu Wotu (This card is only available for games supported by Go Cash recharge)
    <div class="space"></div>
    Product Description:
    <div class="space"></div>
    1. This card only supports USD game accounts. If the game recharge interface displays euros or other items, recharging is not supported!!!
    <div class="space"></div>
    2. Do not disclose the card number and password to others before recharging. Once the recharge is successful, the system will not provide recharge correction services.`,
  },
  {
    title: "ACard",
    type: "Point Card",
    priceList: [
      { label: "100", val: "4.09" },
      { label: "300", val: "11.39" },
      { label: "500", val: "17.89" },
      { label: "1000", val: "32.86" },
    ],
    img: require("../assets/images/product/6.png"),
    desc: `
    Product Type Point Card
    <div class="space"></div>
    Product brand ACard`,
  },
  {
    title: "efunFunCard",
    type: "Point Card",
    priceList: [
      { label: "100", val: "3.89" },
      { label: "300", val: "10.69" },
      { label: "500", val: "16.89" },
      { label: "1000", val: "32.86" },
    ],
    img: require("../assets/images/product/7.png"),
    desc: `
    Product Type: Point Card
    <div class="space"></div>
    Product brand: Fun Card
    <div class="space"></div>
    Scope of application: efun game platform/efunfun/fun card/fun card
    <div class="space"></div>
    Product Description:
    <div class="space"></div>
    1. This product is an electronic card and generally does not have any errors. If there is an error in the card number, please remove the space after the card number.
    <div class="space"></div>
    2. Do not disclose the card number and password to others before recharging. Once the recharge is successful, the system will not provide recharge correction services.`,
  },
  // {
  //   title: "JOY",
  //   img: require("../assets/images/product/8.png"),
  //   desc: `
  //   Product type: recharge card
  //   <div class="space"></div>
  //   Product brand: GASH
  //   <div class="space"></div>
  //   Scope of application: Taiwan Orange GASH Taiwan Universal Point Card`,
  // },
  // {
  //   title: "PlayStation",
  //   img: require("../assets/images/product/9.jpeg"),
  //   desc: `
  //   Product type: recharge card
  //   <div class="space"></div>
  //   Product brand: PSN
  //   <div class="space"></div>
  //   Scope of application: PSN PS3 PS4 PS5 PSP PSV recharge card PlayStation Network account dedicated official recharge card
  //   <div class="space"></div>
  //   Product Description:
  //   <div class="space"></div>
  //   1. Do not inform others before recharging the game point card. Once the recharge is successful, the official website does not provide recharge correction services.
  //   <div class="space"></div>
  //   2. If there is an error in the card number, please remove the space after the card number and recharge.`,
  // },
  {
    title: "Steam",
    img: require("../assets/images/product/10.jpeg"),
    desc: `
    Product type: recharge card <br/>
    <div class="space"></div>
    Product brand: Steam<br/>
    <div class="space"></div>
    Scope of application: Hong Kong server Steam for in-game consumption and use<br/>
    <div class="space"></div>
    Product Description:<br/>
    <div class="space"></div>
    1. Do not inform others before recharging the game point card. Once the recharge is successful, the official website does not provide recharge correction services.<br/>
    <div class="space"></div>
    2. If there is an error in the card number, please remove the space after the card number and recharge.
    `,
  },
  {
    title: "kkbox",
    type: "Point Card",
    priceList: [
      { label: "150", val: "5.09" },
      { label: "300", val: "10.15" },
      { label: "500", val: "14.89" },
      { label: "1000", val: "28.86" },
    ],
    img: require("../assets/images/product/11.png"),
    desc: `
    Applicable scope:
    <div class="space"></div>
    Taiwan kkbox
    <div class="space"></div>
    How to receive the goods:
    <div class="space"></div>
    1. After successful payment, the system will automatically send the card password to your user center, find the corresponding order number, click to view the details, and then extract the card password.
    <div class="space"></div>
    Payment method:
    <div class="space"></div>
    1. Supports various credit card and PayPal account payments, including but not limited to Visa, MasterCard, Maestro, Discover, American Express, JCB, Carte Aurore, Carte Bancaire, Lastchrift, Postpay, Cofinoga ou Private è ge, 4 é tolles
    <div class="space"></div>
    2. Support bank remittance and KA-CN user balance payment`,
  },
  {
    title: "Mobage",
    img: require("../assets/images/product/12.png"),
    desc: `
    Product Type: Point Card
    <div class="space"></div>
    Product brand: Japanese clothing
    <div class="space"></div>
    Scope of application: Mobage Blue Fantasy Japanese mobile game Mobage/mobile: DeNA`,
  },
  {
    title: "Netflix",
    img: require("../assets/images/product/13.jpeg"),
    desc: `
    Product Type: Point Card
    <div class="space"></div>
    Product brand: Netflix
    <div class="space"></div>
    Scope of application: Netflix gift card, limited to Hong Kong account recharge, billing package in Hong Kong dollars
    <div class="space"></div>
    Product Description:
    <div class="space"></div>
    1. Please confirm that your IP is Hong Kong before recharging
    <div class="space"></div>
    2. Do not disclose the card number and password to others before recharging. Once the recharge is successful, the system will not provide recharge correction services.
    <div class="space"></div>
    If you encounter an error in redeeming gifts, please try again later, you need to clear the Netflix cookie in your web browser`,
  },
  {
    title: "OhMyGod",
    type: "Point Card",
    priceList: [
      { label: "150", val: "5.79" },
      { label: "300", val: "11.67" },
      { label: "500", val: "15.59" },
      { label: "1000", val: "30.86" },
    ],
    img: require("../assets/images/product/14.png"),
    desc: `
    Product Type Game Point Card
    <div class="space"></div>
    Product brand OMG
    <div class="space"></div>
    Scope of application: OMG Maowei Divine Weapon Legend, TERA, RF, Kung Fu Hero, Bella, Fantasy Star, Luna, Pet Forest, Dream Wings, Kung Fu World, MAT Anti Terror Action, Feifei, Shenbi, Sovereign, and other stored value games. Please refer to the official website for details.`,
  },
  {
    title: "游e卡",
    type: "Point Card",
    priceList: [
      { label: "150", val: "5.79" },
      { label: "300", val: "10.79" },
      { label: "500", val: "16.59" },
      { label: "1000", val: "34.86" },
    ],
    img: require("../assets/images/product/15.png"),
    desc: `
    Product Type Game Point Card
    `,
  },
  {
    title: "webmoney",
    type: "Point Card",
    priceList: [
      { label: "1000", val: "9.46" },
      { label: "3000", val: "27.79" },
      { label: "5000", val: "43.19" },
      { label: "10000", val: "86.63" },
    ],
    img: require("../assets/images/product/16.png"),
    desc: `
    Product Type Game Point Card
    <div class="space"></div>
    Product brand webmoney`,
  },
  {
    title: "GC卡",
    type: "Point Card",
    priceList: [
      { label: "150", val: "5.79" },
      { label: "300", val: "9.79" },
      { label: "600", val: "18.41" },
      { label: "1000", val: "33.86" },
    ],
    img: require("../assets/images/product/17.png"),
    desc: `
    Product Type Point Card
    <div class="space"></div>
    Product brand GC card`,
  },
  // {
  //   title: "戏谷卡",
  //   img: require("../assets/images/product/18.png"),
  //   desc: `
  //   Product Type Point Card
  //   <div class="space"></div>
  //   Product brand GC card`,
  // },
  {
    title: "DNF mobile game",
    img: require("../assets/images/product/19.jpeg"),
    desc: `
    Product type manual recharge
    <div class="space"></div>
    Product brand Korean DNF mobile game`,
  },
  {
    title: "PUBG2",
    img: require("../assets/images/product/20.jpeg"),
    desc: `Product type: redemption code
    <div class="space"></div>
    Product Brand: PUBG2
    <div class="space"></div>
    Product Description: Recharge Guide - How to Redeem NC at NEW START MOBILE
    <div class="space"></div>
    1. Go to the NEW DATE MOBILE redemption page and enter the received NC redemption code.
    <div class="space"></div>
    2. Enter your game account ID, redeem and use NC.`,
  },
  {
    title: "Blade&Soul 2",
    img: require("../assets/images/product/21.png"),
    desc: `Product brand: Blade&Soul 2
    <div class="space"></div>
    Product Description:
    <div class="space"></div>
    1. Mobile game international server, login method: Google Play, Facebook, Twitter, LINE, DMM GAMES. Information required: account, password, role name
    <div class="space"></div>
    2. If you are logging in with an Apple ID, it is recommended that you recharge your Apple account balance
    <div class="space"></div>
    Please do not log in to the game during the recharge process to avoid card issues
    <div class="space"></div>
    Due to the risk of remote login being frozen on Facebook, please bind at least 2 login methods for users logging in using FB
    `,
  },
  {
    title: "Roblox",
    img: require("../assets/images/product/22.jpeg"),
    desc: `Product brand: Roblox`,
  },
  {
    title: "Arena of Valor",
    img: require("../assets/images/product/23.jpeg"),
    desc: `Product brand: Arena of Valor
    <div class="space"></div>
    Product Description:
    <div class="space"></div>
    1. Mobile game international server, login method: Google Play, Facebook, Twitter, LINE, DMM GAMES. Information required: account, password, role name
    <div class="space"></div>
    2. If you are logging in with an Apple ID, it is recommended that you recharge your Apple account balance
    <div class="space"></div>
    Please do not log in to the game during the recharge process to avoid card issues
    <div class="space"></div>
    Due to the risk of remote login being frozen on Facebook, please bind at least 2 login methods for users logging in using FB`,
  },
  {
    title: "PO币 PO18 POPO",
    img: require("../assets/images/product/24.jpeg"),
    desc: `Product brand: PO币`,
  },
  {
    title: "WGS",
    img: require("../assets/images/product/25.jpeg"),
    desc: `Product type: recharge card
    <div class="space"></div>
    Product brand: WGS
    <div class="space"></div>
    Scope of application: Taiwan wayi Huayi WGS GE King's Sword R/Hot blooded Three Kingdoms 3/SF2Online
    <div class="space"></div>
    Product Description:
    <div class="space"></div>
    1. This product is an electronic card and generally does not have any errors. If there is an error in the card number, please remove the space after the card number.
    <div class="space"></div>
    2. Do not disclose the card number and password to others before recharging. Once the recharge is successful, the system will not provide recharge correction services.`,
  },
];

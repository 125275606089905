import Login from "../src/pages/Login";
import Signup from "../src/pages/Signup";
import PswForget from "../src/pages/PswForget";
import Layout1 from "../src/pages/Layout1/Layout_1";
import PrivacyPolicy from "../src/pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "../src/pages/RefundPolicy/RefundPolicy";
import TermsOfService from "../src/pages/TermsOfService/TermsOfService";
import tiktokCharge from "../src/pages/Layout2/Layout2";
import bigoliveCharge from "../src/pages/Layout3/Layout3";
import payment from "../src/pages/Layout4/Layout4";
import paymentResults from "../src/pages/Layout5/Layout5";
import liveMeCharge from "../src/pages/Layout10/Layout10";
import mycardCharge from "../src/pages/Layout11/Layout11";
import nintendoCharge from "../src/pages/Layout12/Layout12";
import garenaCharge from "../src/pages/Layout13/Layout13";
import razerGoldCharge from "../src/pages/Layout14/Layout14";
import karmaKoinCharge from "../src/pages/Layout15/Layout15";
import Product from "../src/pages/Product";
const routes = [
  { path: "/password-forget", component: PswForget },
  { path: "/sign-up", component: Signup },
  { path: "/login", component: Login },
  { path: "/privacyPolicy", component: PrivacyPolicy },
  { path: "/refundPolicy", component: RefundPolicy },
  { path: "/termsOfService", component: TermsOfService },
  { path: "/tiktokCharge", component: tiktokCharge },
  { path: "/bigoliveCharge", component: bigoliveCharge },
  { path: "/liveMeCharge", component: liveMeCharge },
  { path: "/mycardCharge", component: mycardCharge },
  { path: "/nintendoCharge", component: nintendoCharge },
  { path: "/garenaCharge", component: garenaCharge },
  { path: "/razerGoldCharge", component: razerGoldCharge },
  { path: "/karmaKoinCharge", component: karmaKoinCharge },
  { path: "/payment", component: payment },
  { path: "/payment-results", component: paymentResults },
  { path: "/product/:id", component: Product },
  { path: "/", component: Layout1 },
];

export default routes;

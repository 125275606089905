import React, { Component, Suspense } from "react";

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));
import tiktok from "../../assets/images/my-pic/tiktok@245x245.png";
import visaImg from "../../assets/images/my-pic/visaImg@130x40.png";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
class tiktokCharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
      currentVal: '9.00',
      priceList:[
        {label: 600, val: '9.00'},
        {label: 1800, val:'26.99'},
        {label: 3600, val:'48.99'},
        {label: 7200, val: '86.99'},
      ],
      selectIndex:0
    };
  }

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };
  selectTypeFunc = (data, index) => {
    const { val } = data;
    this.setState({
      selectIndex: index,
      currentVal: val
    });
  }
  render() {
    const {priceList, selectIndex, currentVal} = this.state;
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={'bg-nav'}
            imglight={this.state.imglight}
          />
          <Container className="charge-page">
            <h2 className="fw-bold" style={{marginBottom:'40px'}}>TikToK</h2>
            <Row className="align-items-center mb-5">
              <Col md={5} className="order-1 order-md-1">
                <div className="ms-5 features-img">
                  <img src={tiktok} alt="" className="img-fluid d-block mx-auto rounded shadow"/>
                </div>
              </Col>
              <Col md={7} className="order-2 order-md-2 ms-md-auto  mt-md-0 mt-5">
                <div className="charge-title">TikTok (overseas version )</div>
                <span className="charge-tips">Overseas version, manual processing, please contact customer service by email</span>
                <section className="charge-item text-item">
                  <span className="label">Price：</span>
                  <span className="value">USD ${currentVal}</span>
                </section>
                <section className="charge-item text-item" style={{marginBottom:'12px'}}>
                  <span className="label">Type：</span>
                  <span className="bg-value">gold</span>
                </section>
                <section className="charge-item">
                  <span className="label">value：</span>
                  <ul className="price-list">
                    {priceList.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={selectIndex === index ? 'on' : ''}
                          onClick={() => this.selectTypeFunc(item, index)}
                        >
                          {item.label}
                          <span />
                        </li>
                      );
                    })}
                  </ul>
                </section>
                <Link to="/payment">
                  <button
                    type="submit"
                    name="send"
                    className="btn btn-primary buy-btn"
                  >
                    Buy
                  </button>
                </Link>
				<img src={visaImg} alt="" className="img-fluid d-block pay-way-img"/>
              </Col>
            </Row>
            <div className="cut-line"/>
            <Row className="charge-description align-items-center mb-5">
              <Col>
                <h3 className="fw-bold" style={{marginBottom:'40px'}}>Product desciption</h3>
                <section>
                  <p>Product type: TikTok recharge</p>
                  <p>Scope of application: TikTok (overseas version) gold coins are used for in-app consumption.</p>
                  <p>TikTok was developed by ByteDance and is aimed at foreign users. It provides a popular short video app for overseas users, so that everyone can show their lives. TikTok is rich in functions, whether it is various video editing functions or other special effects, you can easily make them</p>
                </section>
                <section>
                  <p>Product description:</p>
                  <p>1. You need to log in to your TikTok account to recharge. After placing an order successfully, please contact customer service by email</p>
                  <p>2. Do not log in during the recharge period, top account [Fb off-site login has the risk of being banned (any game), it is recommended to bind multiple login methods</p>
                  <p>3. Your TikTok account needs to be bound with a mobile phone number before you can recharge. Tourist accounts cannot be recharged.</p>   
                </section>
                <section>
                <p>Users who have not bound a mobile phone number can bind a mobile phone number as follows: 1. Go to setting and privacy and click Manage account, 2. Bind the registered mobile phone number Phone number in account information</p>
                </section>
              </Col>
            </Row>
          </Container>
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default tiktokCharge;

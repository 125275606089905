import React, { Component, Suspense } from "react";

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));
class TermsOfService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
    };
  }

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={'bg-nav'}
            imglight={this.state.imglight}
          />
          <section className="privacyPolicy-box">
            <h1>Terms of Service</h1>
            <p>Created: June 17, 2023</p>
            <p>
              Thank you (hereinafter referred to as: user) for choosing FuTao, please abide by this service agreement (hereinafter referred to as: this agreement) in the process of using FuTao.
            </p>
            <p>
              Before accepting this agreement, please be sure to read the entire content of this agreement carefully, especially the clauses of exemption or limitation of liability, applicable laws and dispute resolution clauses. If you do not agree to the content of this agreement, please stop using our FuTao service immediately. Any use by you will be deemed as acceptance of this agreement, and means that you agree to be bound by the terms and conditions of FuTao operators together.
            </p>
            <p>
              1. Definition
            </p>
            <p>
              FuTao service: Refers to FuTao operators providing users with online transactions and related technical support services through FuTao official website and client terminal (hereinafter referred to as: FuTao and related services).
            </p>
            <p>
              User: A natural person, legal person or other organization that obtains an authorization and number through the access authorization and passport account registration channels provided by FuTao. 
            </p>
            <p>
              2. Intellectual Property Statement
            </p>
            <p>
              All rights (including copyrights, trademarks, patents, trade secrets and Other related rights) are owned by the FuTao operator or legally authorized by the FuTao operator. Without the permission of FuTao operators or rights holders, no one may use any content in FuTao without authorization, including monitoring, copying, spreading, displaying, mirroring, uploading, downloading, etc. through programs or devices such as robots and spiders.
            </p>
            <p>
              3. About user information
            </p>
            <p>
              (1). Users should provide true, complete and correct user information when registering, and update user information in a timely manner. True and complete user information will serve as the basis for FuTao to provide more services and the prerequisite for users to obtain legal protection.
            </p>
            <p>
              (2). If the user fails to provide true, complete, correct or timely update of user information, the user shall bear all losses and responsibilities arising therefrom, including but not limited to: loss of account password, account disputes with others, etc.
            </p>
            <p>
              4. Instructions for users
            </p>
            <p>
              (1). Users should ensure that the information provided when registering, logging in to FuTao and using FuTao services are true, complete and correct. 
            </p>
            <p>
              (2). FuTao pass account number:
            </p>
            <p className="text-indent-28">
              1) The ownership of the FuTao pass account belongs to FuTao, and the user will obtain the right to use the account after completing the registration application procedures. 
            </p>
            <p className="text-indent-28">
              2) The right to use the FuTao pass account belongs only to the initial applicant registrant. To protect user information and account security, users are prohibited from transferring, inheriting or renting and selling accounts. If FuTao finds that the user is not the initial registrant of the account, FuTao has the right to reclaim the account without taking legal responsibility to the account user.
            </p>
            <p className="text-indent-28">
              3) If there is a dispute between users about the ownership of the account use right, FuTao will make an independent judgment based on the initial personal registration information. The user has the right to recover the disputed account, and the relevant losses and responsibilities shall be borne by the user.
            </p>
            <p className="text-indent-28">
              4) The user is responsible for keeping the account number and password of the FuTao pass, and is fully responsible for all activities under the account number and password. 
            </p>
            <p className="text-indent-28">
              5) If the user registers the FuTao pass account and does not use it for a long time, FuTao company has the right to reclaim the account to avoid waste of resources. 
            </p>
            <p>
              (3). Since the operator of FuTao is the sole copyright owner of FuTao, he has the right to provide paid licenses to users according to law. Users are not allowed to rent, sell or share (paid/free) FuTao services (including free experience services) directly or indirectly, and FuTao will no longer provide any follow-up after-sales service for accounts with sharing behaviors. All disputes and losses incurred due to the user's private transfer shall be borne by the user. At the same time, FuTao reserves the right to pursue legal responsibility against users who violate this agreement.
            </p>
            <p>
              (4). If users find system loopholes, program bugs, etc., they should promptly report to FuTao customer service. If users use system loopholes, program bugs, etc. to seek illegitimate benefits, FuTao has the right to take measures against the relevant accounts, including but not limited to clearing unjust enrichment, Account suspension etc.
            </p>
            <p>
              (5). If the user is under the age of 18 or is a person with limited capacity for civil conduct, he shall read this agreement and use FuTao services under the supervision and guidance of a legal representative, and the legal representative shall complete the registration and use of FuTao services on behalf of the user, and Bear the legal responsibility and all consequences arising therefrom on behalf of the user.
            </p>
            <p>
              (6). For virtual goods, if you enter the wrong account number/mobile phone number bound to the account, wrong amount, improper operation or ignorance, or lack of full understanding of the recharge billing method, etc., the wrong account number, wrong recharge amount, wrong choice of recharge type, etc. are caused. If the rights and interests are damaged due to the situation, the resulting loss shall be borne by the customer, and our company will not make compensation or compensation.
            </p>
            <p>
              (7). After the order is successfully shipped, the user needs to click the Confirm Receipt button on the order interface in time. If the order is not confirmed within 2 hours, the receipt will be automatically confirmed.
            </p>
            <p>
              5. Prohibited actions and responsibilities of users
            </p>
            <p>
              (1). Users shall use FuTao services under the premise of abiding by the law and this agreement. Users are not allowed to implement the following behaviors including but not limited to the following when using FuTao services:
            </p>
            <p className="text-indent-28">
              1) Delete all information and content about copyright on FuTao and other copies without authorization.
            </p>
            <p className="text-indent-28">
              2) Unauthorized reverse engineering, reverse assembly, reverse compilation, etc. of FuTao.
            </p>
            <p className="text-indent-28">
              3) Using, copying, modifying, linking, reprinting, compiling, publishing, and publishing FuTao-related information without authorization, establishing mirror sites, and developing derivative products, works, and services related to it with the help of software without authorization.
            </p>
            <p className="text-indent-28">
              4) Publish, transmit, disseminate, and store content that violates national laws, endangers national security, national unity, and social stability, or any inappropriate, insulting, obscene, violent, and any content that violates national laws, regulations, and policies.
            </p>
            <p className="text-indent-28">
              5) Publish, transmit, disseminate, and store content that infringes on the legal rights of others such as intellectual property rights and trade secret rights.
            </p>
            <p className="text-indent-28">
              6) Use any material or information that contains images or photos obtained by FuTao through infringement of trademarks, copyrights, patents, trade secrets or other proprietary rights of any party.
            </p>
            <p className="text-indent-28">
              7) Conduct any behavior that endangers computer network security, including but not limited to: using unauthorized data or entering unauthorized servers/accounts; entering public computer networks or other people's computer systems without permission and deleting, modifying, adding Store information; without permission, attempt to probe, scan, test the weaknesses of FuTao system or network or other behaviors that undermine network security; attempt to interfere with or destroy the normal operation of FuTao system or website, intentionally spread malicious programs or viruses, and other disruptive interference Behavior of normal network information services; falsification of TCP/IP packet names or parts of names. 
            </p>
            <p className="text-indent-28">
              8) Using FuTao services to directly or indirectly engage in or involve any behaviors expressly prohibited by laws such as money laundering, selling stolen goods, concealing criminal proceeds, gambling, and malicious hoarding.
            </p>
            <p>
              For all responsibilities caused by the user’s violation of the law or the use of this agreement, the user shall bear all responsibilities and have nothing to do with FuTao and its partners. If FuTao and its partners suffer losses, FuTao and its partners have the right to demand compensation from the user, and have Immediately stop providing services to it, keep relevant records, and reserve the right to cooperate with judicial authorities in pursuing legal responsibilities.
            </p>
            <p>
              6. Risk Warning
            </p>
            <p>
              (1). FuTao may be harassed by various security issues, such as others using the user's information to cause harassment in real life; other downloaded and installed software contains viruses such as Trojan horses, threatening the security of the user's computer information and data. Users should strengthen awareness of information security and user data protection, and pay attention to strengthening password protection to avoid loss and harassment.
            </p>
            <p>
              (2). Any other software, websites, programs, etc. derived from FuTao that are not developed and officially released by FuTao operators or authorized by FuTao operators are illegal. Downloading, installing, and using such software, websites, and programs may result in unlawful FuTao has nothing to do with the foreseeable risks and all legal responsibilities and disputes arising therefrom. Users are not allowed to download, install, or use such software, websites, or programs easily, otherwise, FuTao has the right to terminate the user account qualification.
            </p>
            <p>
              Users can only log in and use FuTao services through legal channels provided by FuTao official website and client. Users are not allowed to log in and use FuTao services through other methods not authorized by the FuTao operator, including but not limited to illegally compatible software, programs, or other methods not expressly approved by the FuTao operator, otherwise, FuTao has the right to terminate the user account qualification.
            </p>
            <p>
              7. Privacy Protection and Disclosure
            </p>
            <p>
              It is FuTao's consistent system to respect and protect the privacy of user information resources. When using the FuTao service, the user agrees that the FuTao operator collects, stores, uses, discloses and protects the user's personal information in accordance with the published privacy policy. The operator of FuTao hopes to clearly introduce to users through the privacy policy how to process users' personal information, so it is recommended that users read the privacy policy completely to help better protect users' privacy.
            </p>
            <p>
              8. Service adjustment
            </p>
            <p>
              The user understands and agrees that the FuTao operator has the right to adjust the service content of FuTao after notifying the user in advance in the form of an announcement in accordance with the development of the Internet industry. 
            </p>
            <p>
              9. About value-added services
            </p>
            <p>
              FuTao or its partners will provide users with various Internet value-added services related to this software, including free and paid value-added services, according to the development of the market and technology. FuTao or its cooperative units reserve the right to charge fees for relevant value-added services and change the charging standards and methods; if the relevant services are changed from free to paid services, FuTao and/or cooperative units will notify in an appropriate form, and users can choose to accept or refuse Fee-based services, and guarantee that when using fee-based services, the fees will be paid in accordance with the relevant charging regulations of FuTao and/or cooperative units. If payment is refused or in arrears, FuTao or cooperative units have the right to stop the service and recover losses and compensation according to law.
            </p>
            <p>
              10. Legal liability and disclaimer
            </p>
            <p>
              (1). The user understands and accepts that FuTao involves Internet services, which may be affected by unstable factors in various links, such as force majeure, computer virus, hacker attack, system instability, user location, user shutdown, and any other network and technology. The risk of service interruption or failure to meet the user's requirements caused by , communication lines, etc., the user will bear the above risks, and FuTao and its partners will not bear any responsibility.
            </p>
            <p>
              (2). The user understands and accepts that FuTao and its cooperative units shall not be liable for economic losses suffered by a third party such as the telecommunications department's communication line failure, technical problems, network, computer failure, system instability and other force majeure reasons.
            </p>
            <p>
              (3). The third-party software or technology that FuTao may use is legally authorized. Any disputes arising from third-party software or technology shall be resolved by the third party, and FuTao shall not bear any responsibility. FuTao does not provide customer service support for third-party software or technology. If users need support, please contact the software or technology provider.
            </p>
            <p>
              11. Changes to the agreement
            </p>
            <p>
              FuTao operators may modify this agreement from time to time in accordance with changes in national laws and regulations and the need to maintain transaction order and protect consumer rights. The changed agreement (hereinafter referred to as "changes") will notify users through system announcements or other appropriate methods.
            </p>
            <p>
              If the user does not agree to the change, the user has the right to contact FuTao customer service for feedback before the effective date of the change. If the feedback is adopted, the FuTao operator will adjust the changes as appropriate.
            </p>
            <p>
              If the user still disagrees with the effective changes, the user shall stop using the FuTao service from the effective date of the change, and the change shall not be effective for the user; if the user continues to use the FuTao service after the change becomes effective , it is deemed that the user agrees to the changes that have taken effect.
            </p>
            <p>
              12. Others
            </p>
            <p>
              (1). If part or all of any provision of this agreement is invalid, it will not affect the validity of other provisions.
            </p>
            <p>
              (2). The interpretation, effectiveness and dispute resolution of this agreement shall be governed by the laws of the People's Republic of China. If there is any dispute or controversy between the user and FuTao, it should first be resolved through friendly negotiation. If the negotiation fails, the user hereby fully agrees to submit the dispute or dispute to the jurisdiction of the court where the FuTao operator is located.
            </p>
            <p>
              (3). The FuTao operator reserves all rights to interpret this agreement.
            </p>
          </section>
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default TermsOfService;

const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));
import React, { Component, Suspense } from "react";
import { Link, withRouter} from "react-router-dom";
import visaImg from "../../assets/images/my-pic/visaImg@130x40.png";
import cvcImg from "../../assets/images/my-pic/cvcImg@100x40.png";
//import icon
import FeatherIcon from "feather-icons-react";
import { Col, Container, Form, Input, Label, Row } from "reactstrap";
class payment extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  // goBack = () => {
  //   console.log('props===', props)
  //   // console.log('this.props.history===', this.props.history);
  //   // this.props.history.goBack(-1);
  // }
  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          <Navbar
            navItems={[]}
            navClass={'bg-nav'}
            imglight={true}
          />
          <Container className="payment-page">
            <Row className="payment-page-box">
              <Col lg={6}>
                <div>
                  <Form method="post" name="myForm">
                    <Row>
                      <section className="credit-card">
                        <p className="credit-card-title">Credit card information</p>
                        <Col md={12}>
                          <div className="mb-4 visa-input">
                            <Input
                              name="card_numb"
                              id="card_numb"
                              type="text"
                              className="form-control"
                              placeholder="1234 1234 1234 1234"
                            />
                            <img src={visaImg} alt="" className="img-fluid d-block visa-img"/>
                          </div>
                        </Col>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-4">
                              <Input
                                name="date"
                                id="date"
                                type="text"
                                className="form-control"
                                placeholder="MM/YY"
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-4 visa-input">
                              <Input
                                name="cvc"
                                id="cvc"
                                type="text"
                                className="form-control"
                                placeholder="CVC"
                              />
                              <img src={cvcImg} alt="" className="img-fluid d-block cvc-img"/>
                            </div>
                          </Col>
                        </Row>
                      </section>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-4">
                          <Label
                            htmlFor="frist_name"
                            className="text-muted form-label"
                          >
                            *Name on the card
                          </Label>
                          <Input
                            name="frist_name"
                            id="frist_name"
                            type="text"
                            className="form-control"
                            placeholder="First Name*"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-4">
                          <Label
                            htmlFor="last_name"
                            className="text-muted form-label"
                          >&nbsp;</Label>
                          <Input
                            name="last_name"
                            id="last_name"
                            type="text"
                            className="form-control"
                            placeholder="Last Name*"
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-4">
                          <Label
                            htmlFor="street"
                            className="text-muted form-label"
                          >
                            Street Direction
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="street"
                            name="street"
                            placeholder="Enter Street Direction.."
                          />
                        </div>
                        <div className="mb-4">
                          <Label
                            htmlFor="postal_code"
                            className="text-muted form-label"
                          >
                            Postal Code
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="postal_code"
                            name="postal_code"
                            placeholder="Enter Postal Code.."
                          />
                        </div>
                        <div className="mb-4">
                          <Label
                            htmlFor="phone"
                            className="text-muted form-label"
                          >
                            Phone
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Enter Phone.."
                          />
                        </div>
                        <div className="mb-4">
                          <Label
                            htmlFor="email"
                            className="text-muted form-label"
                          >
                            Email 
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Enter Email.."
                          />
                        </div>

                        <div className="mb-4 pb-2">
                          <Label
                            htmlFor="instructions"
                            className="text-muted form-label"
                          >
                            Other instructions
                          </Label>
                          <textarea
                            name="instructions"
                            id="instructions"
                            rows="4"
                            className="form-control"
                            placeholder="Enter Other instructions..."
                          ></textarea>
                        </div>
                        <div className="submit-btn-group">
                          <Link to="/payment-results">
                            <button
                              type="submit"
                              name="send"

                              className="btn btn-primary"
                            >
                              Subimt
                            </button>
                          </Link>
                          <Link to="/tiktokCharge">
                            <button
                              name="Return"
                              className="btn btn-light"
                            >
                              Return
                            </button>
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
          <Footer />
        </Suspense>  
      </React.Fragment>
    );
  }
}
export default withRouter(payment);

import React, { Component, Suspense } from "react";

// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));
class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
    };
  }

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={'bg-nav'}
            imglight={this.state.imglight}
          />
          <section className="privacyPolicy-box">
            <h1>Privacy Policy</h1>
            <p>Created: June 17, 2023</p>
            <p>
              This privacy policy applies to the services provided by Futao Electronics PTE. LTD. (hereinafter referred to as "FuTao" or "we") (hereinafter referred to as "the service"), respects your personal information and data, and will do our best to protect your personal information information and data. Special Note: FuTao (“we”) attaches great importance to user privacy and personal information protection. When you use our products and/or services, we may collect and use your relevant information. Your trust is very important to us. We are well aware of the importance of personal information to you. We will take corresponding security protection measures in accordance with laws and regulations to try our best to keep your personal information safe and controllable. In view of this, we formulate this "Privacy Policy" (hereinafter referred to as "this Policy / this Privacy Policy") and remind you:
            </p>
            <p>
              This privacy policy is closely related to the FuTao Mall service you use and the various business functions included in the service (hereinafter collectively referred to as "our products and/or services"). We hope you read it carefully before using our products and/or services And confirm that you have fully understood the content of this privacy policy, and allow you to make choices you think are appropriate in accordance with the guidelines of this privacy policy.
            </p>
            <p>
              This policy applies to all products and services provided by FuTao. Before using FuTao's products or services, please carefully read and fully understand the content of each clause, especially the clauses of exemption or limitation of liability, applicable laws and dispute resolution clauses. The terms of exemption or limitation of liability will be marked in bold, and you should read them carefully before starting to use after confirming your full understanding and agreement. In the process of reading the agreement, if you have any questions, you can contact our customer service for consultation; if you do not agree with the relevant agreement or any terms in the agreement, you should immediately stop accessing FuTao.
            </p>
            <p>
              If you have any questions, comments or suggestions about the content of this policy or related matters, you can contact us at any time by visiting the FuTao official website 7*24 hours customer service, sending an email to the official email support@futao.online and other contact methods.
            </p>
            <p>
              This Privacy Policy will help you understand the following:
            </p>
            <p>
              1. How we collect and use your personal information
            </p>
            <p>
              When you use our products and/or services, your personal information that we need/may need to collect and use:
            </p>
            <p>
              (1) In order to provide you with the basic functions of our products and/or services, you must authorize us to collect and use the necessary information. If you refuse to provide the corresponding information, you will not be able to use our products and/or services normally.
            </p>
            <p>
              (2) In order to provide you with additional functions of our products and/or services, you may choose to authorize us to collect and use the information. If you refuse to provide it, you will not be able to use the relevant additional functions or achieve the functional effects we intend to achieve, but it will not affect your normal use of the basic functions of our products and/or services.
            </p>
            <p>
              You understand and agree that:
            </p>
            <p className="text-indent-28">
              1) We are committed to creating a variety of products and services to meet your needs. Because we provide you with a wide variety of products and services, and the range of specific products/services that different users choose to use is different, the corresponding basic/additional functions and the type and scope of personal information collected and used will be different. Please refer to the specific The product/service features of the website shall prevail.
            </p>
            <p className="text-indent-28">
              2) In order to ensure your normal use of the functions or services of our products, maintain the normal operation of the basic functions of the products, optimize product performance, improve your experience and protect the security of your account, we will collect your approximate and exact location information, electronic Information such as email addresses, user IDs, phone numbers, payment information, photos and videos, device IDs, and in-app search records. In this regard, we will separately explain to you the purpose, scope and use of the corresponding information by updating this policy, page prompts, etc., and provide you with a way to choose your own consent, and collect and use it after obtaining your express consent. . Anytime you contact FuTao or FuTao affiliates, you may be asked to provide personal information. FuTao and its affiliates may share this personal information and use it in accordance with this Privacy Policy. FuTao and its affiliates may also combine this information with other information to provide and improve our products, services, content and advertising. You are not required to provide the personal information we request, but in many cases, if you choose not to provide it, we will not be able to provide you with our products or services, or respond to any questions you may have.
            </p>
            <p className="text-indent-28">
              3) When you interact, communicate and solve problems with us through text, pictures, voice, video and other means in our products, we may apply for permission to access your photo album, local storage, camera or microphone. If you refuse to authorize, the corresponding function or service may not be used normally, but it will not affect your use of other functions of this product.
            </p>
            <p className="text-indent-28">
              4) In order to provide you with a better service experience, we support you to upload pictures, audio and video, and we will store them in strict accordance with industry-standard security measures. Without your authorization, we will not collect them The pictures, audio and video of the website will be disclosed to third parties or used for other commercial purposes.
            </p>
            <p className="text-indent-28">
              5) In order to make your shopping more convenient or more fun, thereby improving your online shopping experience on FuTao, some of our extended functions may collect and use your personal information. If you do not provide these personal information, you can still shop online, but you may not be able to use these extended functions that can bring you shopping pleasure or you need to fill in some information repeatedly when purchasing certain products. You are fully aware that we do not need your authorization to collect and use personal information in the following situations:
            </p>
            <p className="text-indent-56">
              (1) Related to national security and national defense security
            </p>
            <p className="text-indent-56">
              (2) Related to public security, public health, or major public interests;
            </p>
            <p className="text-indent-56">
              (3) Related to criminal investigation, prosecution, trial and execution of judgments;
            </p>
            <p className="text-indent-56">
              (4) In order to protect the major legal rights and interests of the personal information subject or other individuals such as life and property, but it is difficult to obtain the consent of the individual;
            </p>
            <p className="text-indent-56">
              (5) The collected personal information is disclosed to the public by the personal information subject or guardian;
            </p>
            <p className="text-indent-56">
              (6) Your personal information is collected from legally disclosed information, such as legal news reports, government information disclosure and other channels;
            </p>
            <p className="text-indent-56">
              (7) Necessary to sign a contract at your request;
            </p>
            <p className="text-indent-56">
              (8) Necessary for maintaining the safe and stable operation of the provided products and/or services, such as discovering and handling product and/or service failures;
            </p>
            <p className="text-indent-56">
              (9) Necessary for legal news reporting;
            </p>
            <p className="text-indent-56">
              (10) It is necessary for academic research institutions to carry out statistical or academic research based on public interests, and when providing academic research or description results to the outside world, de-identify the personal information contained in the results;
            </p>
            <p className="text-indent-56">
              (11) Other circumstances stipulated by laws and regulations. ,
            </p>
            <p className="text-indent-28">
              6) Situations where we obtain your personal information from third parties:
            </p>
            <p className="text-indent-56">
              (1) We may obtain the account information that you authorize to share from a third party, and bind your third-party account with your FuTao account after you agree to this Privacy Policy, so that you can directly log in and use our FuTao account through the third-party account. products and/or services.
            </p>
            <p className="text-indent-56">
              (2) We will use your personal information in compliance with relevant laws and regulations after confirming the legality of the source of personal information in accordance with the agreement with the third party.
            </p>
            <p>
              2. What personal information do we collect
            </p>
            <p>
              When you create a FuTao account, purchase products, participate in FuTao promotions, contact us or participate in online surveys, we may collect your various personal information, including your:
            </p>
            <p>
              (1) Basic information (including name, mobile phone number, email address, and other online contact information);
            </p>
            <p>
              (2) Personal identity information (including ID card, passport, bank card and bill, driver's license, etc.);
            </p>  
            <p>
              (3) Network identification information (including system account number, IP address, email address, and passwords, passwords, and password protection answers related to the foregoing);
            </p>
            <p>
              (4) Personal property information (transaction and consumption records, and virtual property information such as discount codes and game redemption codes);
            </p>
            <p>
              (5) Personal Internet access records (including website browsing records, software usage records, and click records);
            </p>
            <p>
              (6) Personal frequently used device information (including hardware model, device MAC address, operating system type, software list unique device identification code (such as IMEI/android ID/IDFA/OPENUDID/GUID, SIM card IMSI information, etc.) Information about the basic situation of the equipment);
            </p>
            <p>
              (7) Personal location information (including IP address, precise positioning information, etc.);
            </p>
            <p>
              (8) Records when you use our products to interact with us, communicate, and solve problems (including but not limited to text, pictures, audio, video, etc.);
            </p>
            <p>
              (9) When you use FuTao products to share content with family and friends, send gift certificates and products, or invite others to use FuTao services, FuTao may collect information related to the above-mentioned people that you provide, such as name, mailing address, email address and phone number. FuTao will use such information to fulfill your request, provide relevant products or services, or achieve anti-fraud purposes.
            </p>
            <p>
              You must authorize us to collect and use your personal information. Our products and/or services include some core functions, which include the functions necessary for online shopping and transaction security. We may collect, save and use the above-mentioned information about you to realize related functions. If you do not provide relevant information, you will not be able to enjoy the products and/or services we provide.
            </p>
            <p>
              3. How we use your personal information
            </p>
            <p>
              We will use the collected personal information in accordance with the provisions of this privacy policy and to realize the functions of our products and/or services.
            </p>
            <p>
              After collecting your personal information, we will de-identify the data through technical means, and the de-identified information will not be able to identify the subject. Please understand and agree that in this case we have the right to use de-identified information; and on the premise of not disclosing your personal information, we have the right to analyze the user database and make commercial use of it.
            </p>
            <p>
              Please note that all personal information you provide when using our products and/or services, unless you delete or reject our collection through system settings, will continue to authorize us to use during your use of our products and/or services .
            </p>
            <p>
              In order to allow you to quickly find the product you need, we may collect device information (including device name, device model, device identification code, operating system and application version, language setting, Resolution, service provider network ID (PLMN)), browser type to provide you with the optimal way to display product information. We will also use your above personal information in order to continuously improve and optimize the above functions
            </p>
            <p>
              You can also accurately find the goods or services you need by searching. We will keep your search content to facilitate your repeated input or show you products associated with your search content. Please note that your search keyword information cannot identify you alone and does not belong to your personal information. We have the right to use it for any purpose; only when your search keyword information interacts with your other information When used in combination and can identify you, during the combined use, we will use your search keyword information as your personal information, and process and protect it together with your search history in accordance with this Privacy Policy.
            </p>
            <p>
              We will collect statistics on the usage of our products and/or services, and may share these statistical information with the public or third parties to show the overall usage trends of our products and/or services. However, these statistics do not contain any personally identifiable information about you.
            </p>
            <p>
              When we display your personal information, we will desensitize your information by means of content replacement and anonymization to protect your information security.
            </p>
            <p>
              When we want to use your personal information for other purposes not specified in this privacy policy, or use the information collected based on specific purposes for other purposes, we will ask for your consent in advance through the form you voluntarily check. agree.
            </p>
            <p>
              Using the personal information we collect, we can promptly notify you of FuTao's latest product releases and promotions. We also use personal information for the following purposes: to help us create, develop, operate, provide and improve our products, services, content and advertising, and for loss prevention and fraud prevention purposes.
            </p>
            <p>
              We may use your personal information (such as date of birth) to verify identity, assist in identifying users, and determine appropriate services. For example, we may use date of birth to determine the age of a FuTao ID account holder. From time to time, we may use personal information to send important notices, such as information about purchases and changes to our terms, conditions and policies. Since such information is crucial to the communication between you and FuTao, you cannot refuse to receive such information. We also use personal information for internal purposes such as auditing, data analysis, and research to improve FuTao's products, services, and communications with customers.
            </p>
            <p>
              If you participate in a sweepstakes, contest or similar promotion, we will use the information you provide to administer such activity.
            </p>
            <p>
              4. Collection and use of non-personal information
            </p>
            <p>
              We also collect data that by itself cannot be directly linked to any specific individual. We may collect, use, transfer and disclose non-personal information for any purpose. Below are some examples of the non-personal information we may collect and how we use it.
            </p>
            <p>
              We will collect information such as occupation, language, zip code, area code, unique device identifier, referral URL, location, and time zone where users are using FuTao products, so that we can better understand customer behavior, improve our products, Services and Advertising.
            </p>
            <p>
              We collect information about customers' activities on our website, as well as information obtained from our other products and services. We will aggregate this information to help us provide more useful information to customers and understand which parts of our website, products and services are most interesting to customers. Aggregated Data is considered Non-Personal Information for the purposes of this Privacy Policy.
            </p>
            <p>
            We may collect and store details about how you use our Services, including search queries. Such information may be used to improve the results provided by our services and make them more relevant. Except in a few cases, we need to ensure that
            </p>
          </section>
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default PrivacyPolicy;

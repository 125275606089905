import React, { Component, Suspense } from "react";
import prods from "../../utils/prod";
// Importing Section
const Navbar = React.lazy(() => import("../../component/Navbar/NavBar"));
const Footer = React.lazy(() => import("../../component/Footer/Footer"));
import tiktok from "../../assets/images/my-pic/karmakoin@245x245.png";
import visaImg from "../../assets/images/my-pic/visaImg@130x40.png";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
class karmaKoinCharge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "",
      currentVal: "7.00",
      priceList: [
        { label: "7 USD", val: "7.00" },
        { label: "20 USD", val: "20.00" },
        { label: "40 USD", val: "40.00" },
        { label: "80 USD", val: "80.00" },
      ],
      selectIndex: 0,
      id: "",
    };
  }

  componentDidMount() {
    this.setState({
      id: this.props.match.params.id,
      priceList:
        prods[this.props.match.params.id]?.priceList || this.state.priceList,
      currentVal: prods[this.props.match.params.id]?.priceList?.[0]?.val,
    });
  }

  //set preloader div
  PreLoader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </div>
    );
  };
  selectTypeFunc = (data, index) => {
    const { val } = data;
    this.setState({
      selectIndex: index,
      currentVal: val,
    });
  };
  render() {
    const { priceList, selectIndex, currentVal } = this.state;
    return (
      <React.Fragment>
        <Suspense fallback={this.PreLoader()}>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={"bg-nav"}
            imglight={this.state.imglight}
          />
          {!!prods[this.state.id] && (
            <Container className="charge-page">
              <h2 className="fw-bold" style={{ marginBottom: "40px" }}>
                {prods[this.state.id].title}
              </h2>
              <Row className="align-items-center mb-5">
                <Col md={5} className="order-1 order-md-1">
                  <div className="ms-5 features-img">
                    <img
                      src={prods[this.state.id].img}
                      alt=""
                      className="img-fluid d-block mx-auto rounded shadow"
                    />
                  </div>
                </Col>
                <Col
                  md={7}
                  className="order-2 order-md-2 ms-md-auto  mt-md-0 mt-5"
                >
                  <div className="charge-title">
                    {prods[this.state.id].title}
                  </div>
                  <span className="charge-tips">
                    Manual processing, please contact customer service by email
                  </span>
                  <section className="charge-item text-item">
                    <span className="label">Price：</span>
                    <span className="value">USD ${currentVal}</span>
                  </section>
                  <section
                    className="charge-item text-item"
                    style={{ marginBottom: "12px" }}
                  >
                    <span className="label">Type：</span>
                    <span className="bg-value">
                      {prods[this.state.id].type || "gold"}
                    </span>
                  </section>
                  <section className="charge-item">
                    <span className="label">value：</span>
                    <ul className="price-list">
                      {priceList.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className={selectIndex === index ? "on" : ""}
                            onClick={() => this.selectTypeFunc(item, index)}
                          >
                            {item.label}
                            <span />
                          </li>
                        );
                      })}
                    </ul>
                  </section>
                  <Link to="/payment">
                    <button
                      type="submit"
                      name="send"
                      className="btn btn-primary buy-btn"
                    >
                      Buy
                    </button>
                  </Link>
                  <img
                    src={visaImg}
                    alt=""
                    className="img-fluid d-block pay-way-img"
                  />
                </Col>
              </Row>
              <div className="cut-line" />
              <Row className="charge-description align-items-center mb-5">
                <Col>
                  <h3 className="fw-bold" style={{ marginBottom: "40px" }}>
                    Product desciption
                  </h3>
                  <section
                    dangerouslySetInnerHTML={{
                      __html: prods[this.state.id].desc,
                    }}
                  ></section>
                </Col>
              </Row>
            </Container>
          )}
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}
export default karmaKoinCharge;
